import React, { useState } from 'react';
import '../../App.css'; 
import Typewriter from 'typewriter-effect';
import VerticalNav from '../VerticalNav';
import Warzone from '../../images/warzone.png';
import Bo6 from '../../images/bo6.png';

function Home() {
    return (
        <div className="home-container">
            <VerticalNav />
            <div className="section section-1">
                <div className="title-container">
                    <h1 className="title">Vatos Supreme</h1>
                    <Subtitle />
                </div>
                <a href="/buy" className="purchase-button">Purchase</a>
                <div className="scroll-arrow-container" onClick={() => document.querySelector('.section-2').scrollIntoView({ behavior: 'smooth' })}>
                    <span className="scroll-arrow">↓</span>
                </div>
            </div>
            <div className="section section-2">
                <StatusPanel />
            </div>
            <div className="section section-3">
                <Testimonials />
            </div>
            <div className="section section-4">
                <FAQ />
            </div>
        </div>
    );
}

const Subtitle = () => {
    return (
        <div className="subtitle-container">
            <Typewriter
                options={{
                    strings: ['The Ultimate Gaming Platform', 'For All Your Gaming Needs'],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                    delay: 75,
                }}
            />
        </div>
    );
}

// const StatusPanel = () => {
//     return (
//         <div className="status-panel-container">
//             <div className="section-header-badge">
//             <h2 className="status-title">Status</h2>
//             </div>
//             <div className="status-panel">
//                 <div className="status-list">
//                     <ul>
//                         <li className="status-item">Call of Duty: MW3 & WZ3</li>
//                         <li className="status-item">FiveM</li>
//                         <li className="status-item">Apex Legends</li>
//                         <li className="status-item">DayZ</li>
//                         <li className="status-item">Destiny 2</li>
//                         <li className="status-item">Overwatch 2</li>
//                         <li className="status-item">Call of Duty: MW2 & WZ2</li>
//                         <li className="status-item">The Finals</li>
//                     </ul>
//                     <ul>
//                         <li className="status-item">Escape from Tarkov</li>
//                         <li className="status-item">Fortnite</li>
//                         <li className="status-item">Call of Duty: MW & WZ</li>
//                         <li className="status-item">PUBG</li>
//                         <li className="status-item">Warframe</li>
//                         <li className="status-item">ARMA 3</li>
//                         <li className="status-item">New World</li>
//                         <li className="status-item">Dark And Darker</li>
//                     </ul>
//                     <ul>
//                         <li className="status-item">Rainbow Six Siege</li>
//                         <li className="status-item">Rust</li>
//                         <li className="status-item">Dead by Daylight</li>
//                         <li className="status-item">Super People</li>
//                         <li className="status-item">Scum</li>
//                         <li className="status-item">Ark: Survival Evolved</li>
//                         <li className="status-item">Battlefield I-V</li>
//                         <li className="status-item">Fall Guys</li>
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// }

const StatusPanel = () => {

    const statusCards = [
        { 
            title: 'Call of Duty: Warzone', 
            image: Warzone,
            features: [
                '• Aim Assist', 
                '• User-friendly macros', 
                '• Recoil Reducer',
                '• Custom Layout',
                '• Advanced Deadzone tweaks',
                '• PC & Console (custom Remote Play)'
            ]
        },
        { 
            title: 'Call of Duty: Black Ops 6', 
            image: Bo6,
            features: [
                '• Aim Assist', 
                '• User-friendly macros', 
                '• Recoil Reducer',
                '• Custom Layout',
                '• Advanced Deadzone tweaks',
                '• PC & Console (custom Remote Play)'
            ]
        },
    ];

    return (
        <div className="status-panel-container">
            <div className="section-header-badge">
                <h2 className="status-title">Status</h2>
            </div>
            <div className="status-panel">
                {statusCards.map((card, index) => (
                    <div key={index} className="status-card">
                        <img src={card.image} alt={card.title} className="status-card-image" />
                        <div className="status-card-content">
                            <h3 className="status-card-title">{card.title}</h3>
                            <ul className="status-card-features">
                                {card.features.map((feature, i) => (
                                    <li key={i} className="status-card-feature">{feature}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


const Testimonials = () => {
    const testimonials = [
        {
            header: "GamerX1988",
            username: "@GamerX1988",
            text: "Vatos Supreme has completely transformed my gaming experience. The features are incredible and the support is top-notch."
        },
        {
            header: "MirageFPS",
            username: "@MirageFPS",
            text: "I can't imagine gaming without Vatos Supreme now. It's a must-have for any serious gamer."
        },
        {
            header: "LoneWolf_x1",
            username: "@LoneWolf_x1",
            text: "Even as a casual gamer, Vatos Supreme has made a huge difference. The interface is user-friendly and the performance is great."
        },
        {
            header: "TechGuru!2",
            username: "@TechGuru!2",
            text: "The best gaming software I've ever used. The features are well thought out and the performance is unmatched."
        },
        {
            header: "RisingStarDE",
            username: "@RisingStarDE",
            text: "Vatos Supreme is a game-changer. The level of detail and customization available is amazing."
        }
    ];


    return (
        <div className="testimonials-container">
            <h2 className="testimonials-title">Testimonials</h2>
            <div className="testimonials">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-card">
                        <div className="testimonial-header">{testimonial.header}</div>
                        <div className="testimonial-username">{testimonial.username}</div>
                        <p className="testimonial-text">{testimonial.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What is Vatos Supreme?",
            answer: "Vatos Supreme began as a fun project but quickly evolved into advanced gaming software, offering precise aim assist for both Mouse & Keyboard and controller players. With customized DS4Windows for controllers and dedicated software for Mouse & Keyboard, the positive feedback inspired Vatos to release this AMAZING software to everyone!"
        },
        {
            question: "How do I purchase a subscription?",
            answer: "Go to the buy page and select the subscription plan that best suits your need."
        },
        {
            question: "What games are supported?",
            answer: "We support all games that got the aim assist feature. We are constantly updating our software to support more games. At this moment we only support Call of Duty: Warzone and Call of Duty: Black Ops 6."
        },
        {
            question: "Is there a free trial available?",
            answer: "No, we do not offer free trials at the moment."
        },
        {
            question: "How can I contact support?",
            answer: "You can contact our support team via the Contact Us page or email us at support@vatossupreme.com or via Discord."
        },
        {
            question: "Can I cancel my subscription anytime?",
            answer: "Yes, we do not automatically renew your subscription. You can cancel your subscription anytime and you will not be charged for the next billing cycle."
        },
        {
            question: "Are there any discounts for long-term subscriptions?",
            answer: "Yes, we offer discounts for 6-month and 12-month subscriptions."
        }
    ];

    return (
        <div className="faq-container">
            <h2 className="faq-title">Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <span className="faq-arrow">{activeIndex === index ? '▲' : '▼'}</span>
                    </div>
                    <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                        {faq.answer}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Home;
