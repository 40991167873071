import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import '../AdminPanel.css'; // Import the CSS for the Admin Panel

const AdminPanel = () => {
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [detailedUsers, setDetailedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

    useEffect(() => {
        // Fetching the online users
        axios.get('https://sweatbuilds-master-production.up.railway.app/api/supreme/online')
            .then(response => {
                const onlineUserList = response.data.users;
                setOnlineUsers(onlineUserList);

                // Fetch detailed user information
                const fetchUserDetails = async () => {
                    const userDetails = await Promise.all(
                        onlineUserList.map(async (onlineUser) => {
                            try {
                                const res = await axios.get(`https://sweatbuilds-master-production.up.railway.app/api/supreme/user-info/${onlineUser.discordId}`);
                                return res.data;
                            } catch (error) {
                                console.error(`Error fetching data for ${onlineUser.discordId}`, error);
                                return null;
                            }
                        })
                    );
                    setDetailedUsers(userDetails.filter(user => user !== null));
                };

                fetchUserDetails();
            })
            .catch(error => {
                console.error('Error fetching online users:', error);
            });
    }, []);

    if (!user || !user.isAdmin) {
        return <div>Unauthorized Access</div>;
    }

    const resetHWID = (userId, productId) => {
        axios.post('https://sweatbuilds-master-production.up.railway.app/api/supreme/reset-hwid', { userId, productId })
            .then(response => {
                alert(response.data.message);
            })
            .catch(error => {
                console.error('Error resetting HWID:', error);
            });
    };

    const extendKey = (userId, productId, months) => {
        axios.post('https://sweatbuilds-master-production.up.railway.app/api/supreme/extend-key', { userId, productId, months })
            .then(response => {
                alert(response.data.message);
            })
            .catch(error => {
                console.error('Error extending key:', error);
            });
    };

    const filteredUsers = detailedUsers.filter((user) =>
        user.discordName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.discordId.includes(searchQuery)
    );

    const renderUserCard = (user) => {
        return user.products.map((product, prodIndex) => (
            <div className="user-card" key={`${user.discordId}-${prodIndex}`}>
                <div className="user-info">
                    <h4>{user.discordName}</h4>
                    <p><strong>Discord ID:</strong> {user.discordId}</p>
                    <p><strong>License Key:</strong> {product.licenseKey || 'N/A'}</p>
                    <p><strong>IP Address:</strong> {user.ip || 'N/A'}</p>
                    <p><strong>HWID:</strong> {product.hwid || 'N/A'}</p>
                    <p><strong>Last Login:</strong> {product.lastLogin ? new Date(product.lastLogin).toLocaleString() : 'N/A'}</p>
                    <p><strong>Product:</strong> {product.productId === 0 ? 'Vatos Supreme Controller' : 'Vatos Supreme MnK'}</p>
                </div>
                <div className="user-actions">
                    <button className="admin-button" onClick={() => resetHWID(user.discordId, product.productId)}>Reset HWID</button>
                    <button className="admin-button" onClick={() => extendKey(user.discordId, product.productId, 1)}>Extend Key (1 Month)</button>
                </div>
            </div>
        ));
    };

    return (
        <div className="admin-dashboard">
            <div className="sidebar">
                <h2>Admin Panel</h2>
                <ul>
                    <li><a href="#users">Online Users</a></li>
                    <li><a href="#logs">Activity Logs</a></li>
                    <li><a href="#products">Manage Products</a></li>
                </ul>
            </div>
            <div className="main-content">
                <input
                    type="text"
                    placeholder="Search by Discord Name or ID"
                    className="search-bar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                
                <div className="card-container">
                    {filteredUsers.length === 0 ? (
                        <p>No users found.</p>
                    ) : (
                        filteredUsers.map((user) => renderUserCard(user))
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
