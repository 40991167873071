import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Instructions.css';
import c1 from '../../images/MnK/Aiming_1.PNG';
import c2 from '../../images/MnK/Aiming_2.PNG';
import c3 from '../../images/MnK/Aiming_3.PNG';
import c4 from '../../images/MnK/Controller_1.PNG';
import c5 from '../../images/MnK/Controller_2.PNG';
import c6 from '../../images/MnK/GamePlay_1.PNG';
import c7 from '../../images/MnK/GamePlay_2.PNG';
import c8 from '../../images/MnK/GamePlay_3.PNG';
import c9 from '../../images/MnK/GamePlay_4.PNG';
import c10 from '../../images/MnK/s_settings.PNG';
import c1c from '../../images/Controller/c1.PNG';
import c2c from '../../images/Controller/c2.PNG';
import c3c from '../../images/Controller/c3.PNG';
import c4c from '../../images/Controller/c4.PNG';
import c5c from '../../images/Controller/c5.PNG';
import c6c from '../../images/Controller/c6.PNG';
import c7c from '../../images/Controller/c7.PNG';
import c8c from '../../images/Controller/c8.PNG';
import c9c from '../../images/Controller/c9.PNG';

const Instructions = () => {
    const [activeGuide, setActiveGuide] = useState('MnK');
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="instructions-container">
            <div className="docs-page-container">
                <h2 className="instructions-title">Vatos Supreme Setup Guide</h2>

                {/* Toggle between Controller and MnK Guide */}
                <div className="guide-toggle">
                    <button
                        className={`toggle-button ${activeGuide === 'MnK' ? 'active' : ''}`}
                        onClick={() => setActiveGuide('MnK')}
                    >
                        MnK Guide
                    </button>
                    <button
                        className={`toggle-button ${activeGuide === 'Controller' ? 'active' : ''}`}
                        onClick={() => setActiveGuide('Controller')}
                    >
                        Controller Guide
                    </button>
                </div>

                {/* MnK Guide */}
                {activeGuide === 'MnK' && (
                    <div className="guide-content">
                        <p>Video and more in depth guide in our Discord server!</p>
                        <h3>MnK Setup Instructions</h3>
                        <p><strong>┌──═━┈1. Extract the "Vatos Supreme V1.0" folder┈━═──┐</strong></p>
                        <p>Extract the "Vatos Supreme V1.0" folder anywhere on your PC.</p>

                        <p><strong>┌──═━┈2. Run Vatos Supreme.exe┈━═──┐</strong></p>
                        <p>Run the Vatos Supreme.exe.</p>

                        <p><strong>┌──═━┈3. Go to Customer Panel┈━═──┐</strong></p>
                        <p>Go to <a href="https://vatos.gg/dashboard" target="_blank" rel="noopener noreferrer">Vatos Supreme</a>.</p>
                        <p>Click on Customer Panel.</p>

                        <p><strong>┌──═━┈4. Log in with Discord┈━═──┐</strong></p>
                        <p>Log in with your Discord account.</p>

                        <p><strong>┌──═━┈5. Find Username and Password┈━═──┐</strong></p>
                        <p>You will find your username and password. Click on Show to reveal your password.</p>

                        <p><strong>┌──═━┈6. Activate Vatos Supreme MnK┈━═──┐</strong></p>
                        <p>Scroll down, click on Vatos Supreme MnK, and then click Activate.</p>

                        <p><strong>┌──═━┈7. Enter License Key and Activate┈━═──┐</strong></p>
                        <p>Enter your license key and click Activate.</p>

                        <p><strong>┌──═━┈8. Enter Login Details in Vatos Supreme.exe┈━═──┐</strong></p>
                        <p>Go back to Vatos Supreme.exe and enter your login details.</p>

                        <p><strong>┌──═━┈9. Wait for Menu to Appear┈━═──┐</strong></p>
                        <p>Wait a few seconds for the menu to appear.</p>

                        <p><strong>┌──═━┈10. Start Vatos Supreme MnK┈━═──┐</strong></p>
                        <p>If your key was activated, you will be able to click the Vatos Supreme MnK Start button.</p>

                        <p><strong>┌──═━┈11. Go to Settings and Install Engine┈━═──┐</strong></p>
                        <p>Once inside the main software, go to Settings.</p>
                        <p>Click on Engine (on the left-hand menu) and click Install Engine.</p>

                        <p><strong>┌──═━┈12. Restart PC and Continue┈━═──┐</strong></p>
                        <p>Restart your PC, then open Vatos Supreme.exe again and log in.</p>

                        <p><strong>┌──═━┈13. Set Optimal Settings┈━═──┐</strong></p>
                        <p>You can leave the settings at their default values; these are the recommended and optimal settings for in-game performance.</p>

                        <p><strong>┌──═━┈14. Click Start Controller┈━═──┐</strong></p>
                        <p>Click on Start Controller, and you will see a notification on the bottom-right of your screen.</p>

                        <p><strong>┌──═━┈15. MnK to Controller Conversion┈━═──┐</strong></p>
                        <p>To activate the MnK to Controller converter, press the `(tilde) key. To toggle it off, press the `(tilde) key again or hold the Right Shift key to temporarily disable it.</p>

                        <p><strong>┌──═━┈16. Troubleshooting┈━═──┐</strong></p>
                        <p>If an error occurs, take a screenshot and send it to support along with the error log. If you cannot close the app, you may need to restart your PC by holding down the power button.</p>

                        <p><strong>┌──═━┈17. Check In-Game Settings┈━═──┐</strong></p>
                        <p>Go to the "Game Settings" folder to ensure that your in-game settings are configured correctly.</p>

                        <h3>In-Game Settings for MnK</h3>
                        <div className="in-game-settings">
                            <img src={c1} alt="In-game settings 1" />
                            <p>↑Aiming tab↑</p>
                            <img src={c2} alt="In-game settings 2" />
                            <p>↑Aiming tab↑</p>
                            <img src={c3} alt="In-game settings 3" />
                            <p>↑Aiming tab↑</p>
                            <img src={c4} alt="In-game settings 4" />
                            <p>↑Controller tab↑</p>
                            <img src={c5} alt="In-game settings 5" />
                            <p>↑Controller tab↑</p>
                            <img src={c6} alt="In-game settings 6" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c7} alt="In-game settings 7" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c8} alt="In-game settings 8" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c9} alt="In-game settings 9" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c10} alt="In-game settings 10" />
                            <p>↑Vatos Supreme settings tab↑</p>
                        </div>
                    </div>
                )}


                {/* Controller Guide */}
                {activeGuide === 'Controller' && (
                    <div className="guide-content">
                        <p>Video and more in depth guide in our Discord server!</p>
                        <h3>Controller Setup Instructions</h3>
                        <p><strong>┌──═━┈1. Install frameworks┈━═──┐</strong></p>
                        <p>Install both of the runtime: 5.0.17 & 6.0.19.</p>
                        <p>Proceed to step 2 and follow instructions.</p>
                        <p><strong>┌──═━┈2. Install ViGEm Bus Driver┈━═──┐</strong></p>
                        <p>Install the ViGEm Bus Driver by double-clicking the .exe file or running as Administrator.</p>
                        <p>Follow installation prompts without changing the default file location.</p>
                        <p><strong>┌──═━┈3. Extracting Vatos Supreme┈━═──┐</strong></p>
                        <p>Extract the "Vatos Supreme V1.0" folder anywhere on your PC.</p>
                        <p>Run the Vatos Supreme.exe and follow the login instructions.</p>
                        <p><strong>┌──═━┈4. Login and Activate┈━═──┐</strong></p>
                        <p>Go to the website, log in via Discord, and activate your license key.</p>
                        <p><strong>┌──═━┈5. Software Checks┈━═──┐</strong></p>
                        <p>The software will check for ViGEmBus and Vatos Supreme Controller. If they are not installed, the software will install them for you.</p>
                        <p><strong>┌──═━┈6. Automatic Start┈━═──┐</strong></p>
                        <p>After the auto-installation, the software will automatically start.</p>
                        <p><strong>┌──═━┈7. Configure Settings┈━═──┐</strong></p>
                        <p>Enter your settings, choose your profile, and you're ready to start your game.</p>
                        <p><strong>┌──═━┈8. Fix Double Input Issues┈━═──┐</strong></p>
                        <p>If you have double input issues, use HidHide to prioritize Virtual DS4 input. If HidHide is complicated, toggle Prio DS4 in the extension tab, then replug your controller, and the Virtual DS4 input should be prioritized.</p>
                        <h3>In-Game Settings for Controller</h3>
                        <div className="in-game-settings">
                            <img src={c1c} alt="In-game settings 1" />
                            <p>↑Aiming tab↑</p>
                            <img src={c2c} alt="In-game settings 2" />
                            <p>↑Aiming tab↑</p>
                            <img src={c3c} alt="In-game settings 3" />
                            <p>↑Aiming tab↑</p>
                            <img src={c4c} alt="In-game settings 4" />
                            <p>↑Controller tab↑</p>
                            <img src={c5c} alt="In-game settings 5" />
                            <p>↑Controller tab↑</p>
                            <img src={c6c} alt="In-game settings 6" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c7c} alt="In-game settings 7" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c8c} alt="In-game settings 8" />
                            <p>↑Gameplay tab↑</p>
                            <img src={c9c} alt="In-game settings 9" />
                            <p>↑Gameplay tab↑</p>
                        </div>
                    </div>
                )}

                <p>More games and instructions will be added soon!</p>
            </div>
        </div>
    );
};

export default Instructions;
