import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard'; 
import Buy from './components/pages/Buy'; 
import NotFound from './components/pages/NotFound'; 
import Warzone from './components/pages/Warzone'; 
import BlackOps6 from './components/pages/BlackOps6'; 
import Apex from './components/pages/Apex'; 
import XDefiant from './components/pages/XDefiant'; 
import Instructions from './components/pages/Instructions'; 
import AdminPanel from './components/AdminPanel';
import TermsOfService from './components/pages/tos';
import { AuthProvider } from './AuthContext'; 

function MainApp() {
  const location = useLocation();

  const isAdminPanel = location.pathname === '/admin-panel';

  return (
    <div className="App">
      <div className="background-overlay"></div>
      <div className="gradient-overlay"></div>

      {!isAdminPanel && <Header />}
      
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/dashboard" element={<Dashboard />} /> 
          <Route path="/warzone" element={<Warzone />} /> 
          <Route path="/black-ops-6" element={<BlackOps6 />} /> 
          <Route path="/apex" element={<Apex />} /> 
          <Route path="/xdefiant" element={<XDefiant />} /> 
          <Route path="*" element={<NotFound />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/tos" element={<TermsOfService />} />
        </Routes>
      </div>
      
      {!isAdminPanel && <Footer />}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>  
      <Router>
        <MainApp />
      </Router>
    </AuthProvider>
  );
}

export default App;
