import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../App.css';
import { AuthContext } from '../../AuthContext'; 

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); 

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      login(); 
      navigate('/dashboard'); 
    }
  }, [login, navigate]);

  const handleDiscordLogin = () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1271024052051509280&redirect_uri=https://sweatbuilds-master-production.up.railway.app/auth/callback&response_type=code&scope=identify`;
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login with Discord</h2>
        <button onClick={handleDiscordLogin} className="discord-button">Login with Discord</button>
        <p>Don't have Discord? <a href="https://discord.com/register" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#a48adb' }}>Create one for free here</a></p>
      </div>
      <div className="circle-container">
        <div className="circle"></div>
      </div>
    </div>
  );
};

export default Login;
