import React from 'react';
import '../App.css';

const VerticalNav = () => {
    const handleClick = (section) => {
        document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="vertical-nav">
            <div className="nav-dot" onClick={() => handleClick('.section-1')}></div>
            <div className="nav-dot" onClick={() => handleClick('.section-2')}></div>
            <div className="nav-dot" onClick={() => handleClick('.section-3')}></div>
            <div className="nav-dot" onClick={() => handleClick('.section-4')}></div>
        </div>
    );
}

export default VerticalNav;
