import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../App.css';
import logo from '../images/controllerLogo.png';
import Cookies from 'js-cookie';

const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);  // Manage menu open/close state
    const location = useLocation();

    useEffect(() => {
        const checkLoginStatus = () => {
            const userCookie = Cookies.get('user');
            setIsLoggedIn(!!userCookie);
        };

        checkLoginStatus();
    }, [location]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);  // Toggle the menu state
    };

    return (
        <header className="header">
            <div className="header-content">
                <img src={logo} alt="Logo" className="logo" onClick={() => window.location.href = "/home"} />
                <div className="logo-title" onClick={() => window.location.href = "/home"}>Vatos Supreme</div>
                <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                    <span className="hamburger-bar"></span>
                    <span className="hamburger-bar"></span>
                    <span className="hamburger-bar"></span>
                </div>
                
                <nav className={`nav ${isMenuOpen ? 'nav-visible' : ''}`}>
                    <ul>
                        {isLoggedIn ? (
                            <>
                                <li><a href="/home">Home</a></li>
                                <li><a href="/instructions">Instructions</a></li>
                                <li><a href="/buy">Buy</a></li>
                                <li><a href="https://discord.gg/sweatvpn">Discord</a></li>
                            </>
                        ) : (
                            <>
                                <li><a href="/tos">TOS</a></li>
                                <li><a href="/roadmap">Roadmap</a></li>
                                <li><a href="https://discord.gg/sweatvpn">Discord</a></li>
                                <li><a href="/resell">Resell</a></li>
                            </>
                        )}
                        <li><a href="/login" className="customer-panel">Customer Panel</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
