import React from 'react';
import '../App.css';


const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© 2024 Vatos Supreme</p>
            </div>
        </footer>
    );
}

export default Footer;