import React from 'react';
import '../../App.css'; 

const Warzone = () => {
    return (
        <div className="docs-page-container">
            <div className="docs-content">
                <h1>Warzone Guide</h1>
                <p className="description-text" style={{textAlign: "center"}}>Welcome to the Warzone guide. Here you will find all the instructions you need to get started.</p>
            </div>
        </div>
    );
}

export default Warzone;