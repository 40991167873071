import React from 'react';
import '../../App.css'; 

const Apex = () => {
    return (
        <div className="docs-page-container">
            <div className="docs-content">
                <h1>Apex Guide</h1>
                <p className="description-text" style={{textAlign: "center"}}>Welcome to the Apex guide. Here you will find all the instructions you need to get started.</p>
                <h5>Coming soon!</h5>
            </div>
        </div>
    );
}

export default Apex;