import React, { useState } from 'react';

const ProductCard = ({ imgSrc, title, prices, onBuyNowClick }) => { // Add onBuyNowClick prop
    const [selectedPrice, setSelectedPrice] = useState(prices[0]);

    const handleSelectChange = (event) => {
        const selectedDuration = event.target.value;
        const selectedPackage = prices.find(price => price.duration === selectedDuration);
        setSelectedPrice(selectedPackage);
    };

    return (
        <div className="card-2">
            <img src={imgSrc} alt={title} style={{ width: '50px', height: '50px' }} />
            <h2>{title}</h2>
            <div className="package-selection">
                <select onChange={handleSelectChange} value={selectedPrice.duration}>
                    {prices.map((price, index) => (
                        <option key={index} value={price.duration}>
                            {price.duration}: ${price.amount}
                        </option>
                    ))}
                </select>
                <p>{selectedPrice.info}</p>
                {/* Use the passed onBuyNowClick function */}
                <button className="cart-button" onClick={onBuyNowClick}>
                    Buy Now
                </button>
            </div>
        </div>
    );
}

export default ProductCard;
