import React from 'react';
import '../../App.css'; 

const BlackOps6 = () => {
    return (
        <div className="docs-page-container">
            <div className="docs-content">
                <h1>Black Ops 6 Guide</h1>
                <p className="description-text" style={{textAlign: "center"}}>Welcome to the Black Ops 6 guide. Here you will find all the instructions you need to get started.</p>
            </div>
        </div>
    );
}

export default BlackOps6;